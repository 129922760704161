<template>
    <div class="mini_game_bet">
        <div class="mini_game">
            <div class="m1 dmr">
                <div class="mini_fram">
                    <leisure-game-ifram-view>
                        <iframe id="skypark-gamefram" name="stream_frm" scrolling="no" frameborder="0"
                                src="https://jp-domi.lock-global.com/game/dragontiger/">
                        </iframe>
                    </leisure-game-ifram-view>
                </div>
            </div>

            <div class="m2">
                <div class="game_tab">
                    <leisure-bet-masker :show-masker="showMasker"></leisure-bet-masker>
                    <leisure-timer-comp
                            ref="ltimer"
                            :leisure-game-info="leisureGameInfo"
                            @timerStop="timerStop" @refreshTimer="refreshTimer">
                        <span slot="title" class="text-orange"><i class="fa fa-star"></i>드래곤타이거 </span>
                    </leisure-timer-comp>

                    <!--선택부분-->
                    <div class="mini_bet_box">
                        <div class="mg">
                            <span class="t">Dragon&Tiger</span>
                            <div class="mg_btn b3"
                                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_CANDY_DRAGON_TIGER_NORMAL && selectedInfo.selectedWay === 1}"
                                 @click="selected(leisureConst.LEISURE_ATTRIBUTE_CANDY_DRAGON_TIGER_NORMAL,leisureGameInfo.leisureGame.id,1, normalConfig.odds1, normalConfig, 'Dragon')">
                                <div class="r rblue">
                                    <span class="n">Dragon</span>
                                    <span class="b">{{normalConfig.odds1}}</span>
                                </div>
                            </div>

                            <div class="mg_btn b3"
                                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_CANDY_DRAGON_TIGER_NORMAL && selectedInfo.selectedWay ===3}"
                                 @click="selected(leisureConst.LEISURE_ATTRIBUTE_CANDY_DRAGON_TIGER_NORMAL,leisureGameInfo.leisureGame.id,3,  normalConfig.odds3, normalConfig, 'Tie')">
                                <div class="r rgreen">
                                    <span class="n">Tie</span>
                                    <span class="b">{{normalConfig.odds3}}</span>
                                </div>
                            </div>

                            <div class="mg_btn b3"
                                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_CANDY_DRAGON_TIGER_NORMAL && selectedInfo.selectedWay ===2}"
                                 @click="selected(leisureConst.LEISURE_ATTRIBUTE_CANDY_DRAGON_TIGER_NORMAL,leisureGameInfo.leisureGame.id,2,  normalConfig.odds2, normalConfig, 'Tiger')">
                                <div class="r rred">
                                    <span class="n">Tiger</span>
                                    <span class="b">{{normalConfig.odds2}}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--파워볼 베팅카트-->
                    <leisure-bet-cart-comp
                            ref="betCart" :kind="kind"
                            :selected-info="selectedInfo">
                        Dragon&Tiger
                    </leisure-bet-cart-comp>


                </div>
            </div>
            <div class="m3">
                <!--최근 배팅내역-->
                <leisure-lately-bet-list-comp
                        ref="latelyBetList"
                        :kind="kind"></leisure-lately-bet-list-comp>
            </div>


        </div>
    </div>
</template>

<script>
    import SubTitle from "../../../components/SubTitle";
    import LeisureTimerComp from "../../../components/leisuregame/LeisureTimerComp";
    import LeisureBetCartComp from "../../../components/leisuregame/LeisureBetCartComp";
    import leisureConst from "../../../common/leisureConst";
    import sportsConst from "../../../common/sportsConst";
    import LeisureBetMasker from "../../../components/leisuregame/LeisureBetMasker";
    import {leisureGameMixin} from "../../../common/mixin";
    import {getLeisureGameAttributeConfig, getLotusToken} from "../../../network/leisureRequest";
    import LeisureLatelyBetListComp from "../../../components/leisuregame/LeisureLatelyBetListComp";
    import LeisureGameView from "../../../components/leisuregame/LeisureGameView";
    import LeisureGameLinks from "../LeisureGameLinks";
    import LeisureGameIframView from "../LeisureGameIframView";
    import LeisureCompGameLinks from "../LeisureCompDonghangGameLinks";
    import {RECEIVE_CURRENTLEISUREGAMECOMP, RECEIVE_CURRENTLEISUREGAMEID} from "../../../store/mutation-types";

    export default {

        name: "CandyDragonTiger",
        mixins: [leisureGameMixin],
        components: {
            LeisureCompGameLinks,
            LeisureGameIframView,
            LeisureGameLinks,
            LeisureGameView,
            LeisureLatelyBetListComp, LeisureBetMasker, LeisureBetCartComp, LeisureTimerComp, SubTitle
        },
        data() {
            return {
                kind: leisureConst.LEISURE_KIND_CANDY_DRAGON_TIGER,
            }
        },

        methods: {
            initLeisureGameArttributeConfig() {
                getLeisureGameAttributeConfig().then(res => {
                    if (res.data.success) {
                        this.attrConfig = res.data.data
                        //배당설정
                        this.normalConfig = this.attrConfig.find(attr => {
                            return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_CANDY_DRAGON_TIGER_NORMAL)
                        })
                    }
                })
            },

        },
        created() {
            this.initKindConfig(this.kind)
            this.$store.commit(RECEIVE_CURRENTLEISUREGAMECOMP, leisureConst.LEISURE_COMP_CANDY);
            this.$store.commit(RECEIVE_CURRENTLEISUREGAMEID, this.kind);
        }
    }
</script>

<style scoped>
    .mini_game .mini_fram {
        margin-top: 15px;
    }

    @media screen and (max-width: 1024px) {
        /*.mini_game .mini_fram{*/
        /*    margin-top: 175px;*/
        /*}*/
        .dmr {
            min-height: 400px !important;
        }


        .mini_game .game_tab {
            background-color: var(--minigamebg);
            margin-top: 120px!important;
        }
    }

    @media screen and (max-width: 414px) {
        #skypark-gamefram {
            border: 0 !important;
            position: absolute !important;
            transform: scale(0.38) !important;
            left: -210px;
            top: -300px;
        }
    }

    @media screen and (max-width: 393px) {
        #skypark-gamefram {
            border: 0 !important;
            position: absolute !important;
            transform: scale(0.38) !important;
            left: -220px;
            top: -300px;
        }
    }

    @media screen and (max-width: 390px) {
        #skypark-gamefram {
            border: 0 !important;
            position: absolute !important;
            transform: scale(0.39) !important;
            left: -230px;
            top: -295px;
        }
    }

    @media screen and (max-width: 375px) {
        #skypark-gamefram {
            border: 0 !important;
            position: absolute !important;
            transform: scale(0.38) !important;
            left: -240px;
            top: -300px;
        }
    }

    @media screen and (max-width: 360px) {
        #skypark-gamefram {
            border: 0 !important;
            position: absolute !important;
            transform: scale(0.38) !important;
            left: -240px;
            top: -300px;
        }
    }
</style>